import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Sidebar from './Sidebar';
import bookingService from '../services/bookingService';
import CopyToClipboardButton from './CopyToClipboard';
import { format, isAfter, isBefore, parseISO, compareAsc, subMonths } from 'date-fns';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Cell } from 'recharts';
import { Paper, List, ListItem, ListItemText, Divider, Link, Grid } from '@mui/material';

const Dashboard = () => {
  const [bookings, setBookings] = useState([]);
  const [currentMonthBookings, setCurrentMonthBookings] = useState(0);
  const [nextBookings, setNextBookings] = useState([]);
  const [currentActiveBookings, setCurrentActiveBookings] = useState([]);
  const [monthlyBookings, setMonthlyBookings] = useState([]);
  const { logout, user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    const fetchBookings = async () => {
      const token = await getAccessTokenSilently();
      const response = await bookingService.getBookings(token);
      const bookingData = response.data;

      // Filter bookings based on the current month
      const now = new Date();
      const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
      const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);

      const bookingsThisMonth = bookingData.filter((booking) => {
        const checkIn = parseISO(booking.check_in);
        return isAfter(checkIn, startOfMonth) && isBefore(checkIn, endOfMonth);
      });

      setCurrentMonthBookings(bookingsThisMonth.length);

      // Group bookings by rental
      const groupedBookings = bookingData.reduce((acc, booking) => {
        const rentalName = booking.rental.rental_name || 'Unknown Rental';
        if (!acc[rentalName]) acc[rentalName] = [];
        acc[rentalName].push(booking);
        return acc;
      }, {});

      // Find the next booking for each rental property
      const nextBookingsForAllRentals = Object.keys(groupedBookings).map(rental => {
        const futureBookings = groupedBookings[rental]
          .filter(booking => isAfter(parseISO(booking.check_in), now))
          .sort((a, b) => compareAsc(parseISO(a.check_in), parseISO(b.check_in)));

        return futureBookings.length > 0 ? {
          rental: rental,
          booking: futureBookings[0],
        } : null;
      }).filter(Boolean);
      setNextBookings(nextBookingsForAllRentals);

      // Find the current active booking for each rental property
      const currentActiveForAllRentals = Object.keys(groupedBookings).map(rental => {
        const activeBooking = groupedBookings[rental].find(booking => {
          const checkIn = parseISO(booking.check_in);
          const checkOut = parseISO(booking.check_out);
          return isBefore(checkIn, now) && isAfter(checkOut, now);
        });
        return activeBooking ? { rental: rental, booking: activeBooking } : null;
      }).filter(Boolean);
      setCurrentActiveBookings(currentActiveForAllRentals);

      // Calculate bookings per month for the last 12 months + 3 future months
      const monthsRange = 12 + 3; // 12 past months + 3 future months
      const bookingsByMonth = [];

      for (let i = 0; i < monthsRange; i++) {
        const month = subMonths(now, i - 3); // shift to include future months
        const bookingsInMonth = bookingData.filter(booking => {
          const checkIn = parseISO(booking.check_in);
          return checkIn.getMonth() === month.getMonth() && checkIn.getFullYear() === month.getFullYear();
        });
        bookingsByMonth.push({
          month: format(month, 'MMM yyyy'),
          count: bookingsInMonth.length,
          isFuture: isAfter(month, now) // Mark future months
        });
      }

      setMonthlyBookings(bookingsByMonth.reverse());

      setBookings(bookingData);
    };

    fetchBookings();
  }, [getAccessTokenSilently]);

  return (
    <div style={{ display: 'flex' }}>
      <Sidebar onLogout={logout} collapsed={collapsed} toggleCollapse={toggleCollapse} />
      <div style={{ flex: 1, padding: '16px', marginLeft: collapsed ? '0px' : '16px', transition: 'margin-left 0.3s' }}>
        <h1>Dashboard</h1>
        {isAuthenticated ? (
          <>
            <p>Welcome, {user.name}!</p>
            <button onClick={() => logout({ returnTo: window.location.origin })}>Log out</button>

            <Grid container spacing={2} style={{ marginTop: '20px' }}>

              {/* Current Active Booking Tile */}
              <Grid item xs={12} sm={6} md={4}>
                <Paper style={{ padding: '20px', background: '#f0f0f0', borderRadius: '8px' }}>
                  <h3>Current Active Booking</h3>
                  {currentActiveBookings.length > 0 ? (
                    <List>
                      {currentActiveBookings.map(({ rental, booking }, index) => (
                        <React.Fragment key={index}>
                          <ListItem>
                            <ListItemText
                              primary={rental}
                              secondary={
                                <>
                                  <div>Guest: {booking.guest_name}</div>
                                  <div>Check-Out: {format(parseISO(booking.check_out), 'PPP')}</div>
                                  <div>
                                    <Link href={booking.guest_dashboard_link} target="_blank" rel="noopener">
                                      Guest Dashboard
                                    </Link>
                                    <CopyToClipboardButton url={booking.guest_dashboard_link} />
                                  </div>
                                </>
                              }
                            />
                          </ListItem>
                          {index < currentActiveBookings.length - 1 && <Divider />}
                        </React.Fragment>
                      ))}
                    </List>
                  ) : (
                    <p>No active bookings</p>
                  )}
                </Paper>
              </Grid>

              {/* Next Booking Tile */}
              <Grid item xs={12} sm={6} md={4}>
                <Paper style={{ padding: '20px', background: '#f0f0f0', borderRadius: '8px' }}>
                  <h3>Next Booking</h3>
                  {nextBookings.length > 0 ? (
                    <List>
                      {nextBookings.map(({ rental, booking }, index) => (
                        <React.Fragment key={index}>
                          <ListItem>
                            <ListItemText
                              primary={rental}
                              secondary={
                                <>
                                  <div>Guest: {booking.guest_name}</div>
                                  <div>Check-In: {format(parseISO(booking.check_in), 'PPP')}</div>
                                  <div>
                                    <Link href={booking.guest_dashboard_link} target="_blank" rel="noopener">
                                      Guest Dashboard
                                    </Link>
                                    <CopyToClipboardButton url={booking.guest_dashboard_link} />
                                  </div>
                                </>
                              }
                            />
                          </ListItem>
                          {index < nextBookings.length - 1 && <Divider />}
                        </React.Fragment>
                      ))}
                    </List>
                  ) : (
                    <p>No upcoming bookings</p>
                  )}
                </Paper>
              </Grid>

              {/* Bookings This Month Tile */}
              <Grid item xs={12} sm={6} md={4}>
                <Paper style={{ padding: '20px', background: '#f0f0f0', borderRadius: '8px', display: 'flex', flexDirection: 'column' }}>
                  <h3 style={{ textAlign: 'left' }}>Bookings This Month</h3>
                  <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <p style={{ fontSize: '48px', fontWeight: 'bold', textAlign: 'center' }}>
                      {currentMonthBookings}
                    </p>
                  </div>
                </Paper>
              </Grid>

              {/* Bookings by Month Chart */}
              <Grid item xs={12}>
                <Paper style={{ padding: '20px', background: '#f0f0f0', borderRadius: '8px' }}>
                  <h3>Booking History</h3>
                  <ResponsiveContainer width="100%" height={300}>
                    <BarChart data={monthlyBookings}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="month" />
                      <YAxis />
                      <Tooltip />
                      <Bar dataKey="count">
                        {monthlyBookings.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={entry.isFuture ? "#82ca9d" : "#8884d8"} // Different color for future months
                          />
                        ))}
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </Paper>
              </Grid>
            </Grid>
          </>
        ) : (
          <p>You are not logged in.</p>
        )}
      </div>
    </div>
  );
};

export default Dashboard;

