import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Box,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import moment from 'moment';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import '../index.css';

const initialFormState = {
  rental_id: '',
  rental_name: '',
  check_in_date: null,
  check_in_time: '16:00',
  check_out_date: null,
  check_out_time: '11:00',
  guest_name: '',
  guest_email: '',
  guest_phone_number: '',
  guest_pincode: '',
}

const BookingForm = ({ open, handleClose, handleFormSubmit, editingBooking, editingBookingRental, rentals }) => {
  const [formState, setFormState] = useState(initialFormState);

  useEffect(() => {
    if (!open) {
      setFormState(initialFormState); // Reset to initial state when form is closed
    }
    if (editingBooking) {
      setFormState({
        rental_id: editingBooking.rental_id || '',
        rental_name: editingBooking.rental_name || '',
        check_in_date: moment(editingBooking.check_in).format('YYYY-MM-DD') || '',
        check_in_time: moment(editingBooking.check_in).format('HH:mm') || '',
        check_out_date: moment(editingBooking.check_out).format('YYYY-MM-DD') || '',
        check_out_time: moment(editingBooking.check_out).format('HH:mm') || '',
        guest_name: editingBooking.guest_name || '',
        guest_email: editingBooking.guest_email || '',
        guest_phone_number: editingBooking.phone_number || '',
        guest_pincode: editingBooking.pincode || '',
      });
    }
  }, [editingBooking]);

   // Generic handle change for form fields
   const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value, // Updates the appropriate field in formState
    }));
  };

return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>{editingBooking ? 'Edit Booking' : 'Add Booking'}</DialogTitle>
      <DialogContent>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box
            component="form"
            id="booking-form"
            onSubmit={handleFormSubmit}
            sx={{ display: 'flex', flexDirection: 'column', gap: 2, minWidth: '500px' }}
          >
            {/* Booking Details Section */}
            <Typography variant="h6">Booking Details</Typography>
            <FormControl fullWidth margin="normal">
              <InputLabel>Rental</InputLabel>
              <Select
                name="rental_id"
                value={formState.rental_id || ''} // Bind to formState
                onChange={handleChange} // Use the same generic handleChange function
                required
              >
                {rentals.map((rental) => (
                  <MenuItem key={rental.id} value={rental.id}>
                    {rental.rental_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
                name="check_in_date"
                label="Check-in Date"
                type="date"
                fullWidth
                margin="normal"
                value={formState.check_in_date}
                onChange={handleChange}
                InputLabelProps={{shrink: true,}}
                required
              />
              <TextField
                name="check_in_time"
                label="Check-in Time"
                type="time"
                fullWidth
                margin="normal"
                value={formState.check_in_time}
                onChange={handleChange}
                InputLabelProps={{shrink: true,}}
                required
              />
              <TextField
                name="check_out_date"
                label="Check-out Date"
                type="date"
                fullWidth
                margin="normal"
                value={formState.check_out_date}
                onChange={handleChange}
                InputLabelProps={{shrink: true,}}
                required
              />
              <TextField
                name="check_out_time"
                label="Check-out Time"
                type="time"
                fullWidth
                margin="normal"
                value={formState.check_out_time}
                onChange={handleChange}
                InputLabelProps={{shrink: true,}}
                required
              />
              <TextField
                name="guest_name"
                label="Guest Name"
                fullWidth
                margin="normal"
                value={formState.guest_name}
                onChange={handleChange}
                required={true}
              />
              <TextField
                name="guest_email"
                label="Guest Email"
                fullWidth
                margin="normal"
                value={formState.guest_email}
                onChange={handleChange}
                required={false}
              />
              <TextField
                name="guest_phone_number"
                label="Phone Number"
                fullWidth
                margin="normal"
                value={formState.guest_phone_number}
                onChange={handleChange}
                required={false}
              />
              <TextField
                name="guest_pincode"
                label="Pin Code"
                fullWidth
                margin="normal"
                value={formState.guest_pincode}
                onChange={handleChange}
                required={false}
              />
          </Box>
        </LocalizationProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button form="booking-form" type="submit" color="primary">
          {editingBooking ? 'Update' : 'Add'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BookingForm;
