import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Box,
  Typography,
  Divider,
  FormControlLabel,
  Checkbox,
  TextField,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import AddressAutocomplete from './AddressAutocomplete';
import rentalService from '../services/rentalService';
import '../index.css';
import bookingService from '../services/bookingService';

// Define initial form state separately for easy resetting
const initialFormState = {
  rental_name: '',
  address: '',
  timezone: '',
  default_check_in_time: '16:00',
  default_check_out_time: '11:00',
  calendar_config: {
    calendar_vrbo_ical_link: '',
    calendar_airbnb_ical_link: '',
    calendar_custom_ical_link: '',
    calendar_integration_enabled: false,
  },
  home_assistant_config: {
    home_assistant_integration_enabled: false,
    home_assistant_host: '',
    home_assistant_api_key: '',
    home_assistant_guest_label: 'staykey-guest-access',
  },
};

const initialStatusCheckState = {
  vrboStatus: '',
  airbnbStatus: '',
  customIcalStatus: '',
  HomeAssistantStatus: '',
};

const RentalForm = ({ open, handleClose, handleFormSubmit, editingRental }) => {
  // Single form state to manage all fields
  const [formState, setFormState] = useState(initialFormState);
  const [statusCheckState, setStatusCheckState] = useState(initialStatusCheckState);

  // Effect to update formState when editingRental changes
  useEffect(() => {
    if (!open) {
      setFormState(initialFormState); // Reset to initial state when form is closed
      setStatusCheckState(initialStatusCheckState)
    }
    if (editingRental) {
      setFormState({
        rental_name: editingRental.rental_name || '',
        address: editingRental.address || '',
        timezone: editingRental.timezone || '',
        default_check_in_time: editingRental.default_check_in_time || '',
        default_check_out_time: editingRental.default_check_out_time || '',
        calendar_config: {
          calendar_vrbo_ical_link: editingRental.calendar_config?.vrbo_ical_link || '',
          calendar_airbnb_ical_link: editingRental.calendar_config?.airbnb_ical_link || '',
          calendar_custom_ical_link: editingRental.calendar_config?.custom_ical_link || '',
          calendar_integration_enabled: editingRental.calendar_config?.integration_enabled || false,
        },
        home_assistant_config: {
          home_assistant_integration_enabled: editingRental.home_assistant_config?.integration_enabled || false,
          home_assistant_guest_label: editingRental.home_assistant_config?.guest_label || '',
          home_assistant_api_key: editingRental.home_assistant_config?.api_key || '',
          home_assistant_host: editingRental.home_assistant_config?.host || '',
        },
      });
      setStatusCheckState(initialStatusCheckState)
    }
  }, [editingRental]);

  // Generic handle change for form fields
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    // Handle boolean fields like checkboxes
    if (type === 'checkbox') {
      setFormState((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
    } else {
      setFormState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleCalendarChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    setFormState((prevState) => ({
      ...prevState,
      calendar_config: {
        ...prevState.calendar_config,
        [name]: type === 'checkbox' ? checked : value,
      },
    }));
  };

  const handleHomeAssistantChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    setFormState((prevState) => ({
      ...prevState,
      home_assistant_config: {
        ...prevState.home_assistant_config,
        [name]: type === 'checkbox' ? checked : value,
      },
    }));
  };

  const handleAddressSelect = async ({ place, latitude, longitude }) => {
    const formattedAddress = place.formatted_address;

    setFormState((prevState) => ({
      ...prevState,
      address: formattedAddress,
    }));

    // Fetch timezone based on coordinates
    const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
    const timestamp = Math.floor(Date.now() / 1000);
    const timeZoneApiUrl = `https://maps.googleapis.com/maps/api/timezone/json?location=${latitude},${longitude}&timestamp=${timestamp}&key=${apiKey}`;

    try {
      const response = await fetch(timeZoneApiUrl);
      const data = await response.json();
      if (data.status === 'OK') {
        setFormState((prevState) => ({
          ...prevState,
          timezone: data.timeZoneId,
        }));
      } else {
        console.error('Failed to fetch timezone:', data.status);
      }
    } catch (error) {
      console.error('Error fetching timezone:', error);
    }
  };

  const handleIcalStatusMessage = async (icalSource, statusMessage) => {

    if (icalSource == 'vrbo') {
      setStatusCheckState({vrboStatus: statusMessage})
      return;
    }
    if (icalSource == 'airbnb') {
      setStatusCheckState({airbnbStatus: statusMessage})
      return;
    }
    if (icalSource == 'customIcal') {
      setStatusCheckState({customIcalStatus: statusMessage})
      return;
    }
  };

  const handleICalLinkTest = async (icalLink, icalSource) => {

    if (!icalLink) {
      handleIcalStatusMessage(icalSource, 'Please provide both host and API key.')
      return;
    }

    try {
      await rentalService.testIcalLink(icalLink);
      handleIcalStatusMessage(icalSource, 'iCal link is valid!');
    } catch (error) {
      handleIcalStatusMessage(icalSource, 'Invalid iCal link.');
    }
  };

  const handleHomeAssistantTest = async () => {
    if (!formState.home_assistant_config.home_assistant_host) {
      setStatusCheckState({
        HomeAssistantStatus: 'Please provide both host and API key.',
      });
      return;
    }
  
    try {
      const response = await rentalService.testHomeAssistantConnection(formState.home_assistant_config.home_assistant_host, formState.home_assistant_config.home_assistant_api_key);
      setStatusCheckState({
        HomeAssistantStatus: 'Connection successful!',
      });
    } catch (error) {
      setStatusCheckState({
        HomeAssistantStatus: 'Failed to connect. Please check the host and API key.',
      });
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>{editingRental ? 'Edit Rental' : 'Add Rental'}</DialogTitle>
      <DialogContent>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box
            component="form"
            id="rental-form"
            onSubmit={handleFormSubmit}
            sx={{ display: 'flex', flexDirection: 'column', gap: 2, minWidth: '500px' }}
          >
            {/* Rental Details Section */}
            <Typography variant="h6">Rental Details</Typography>
            <TextField
              name="rental_name"
              label="Rental Name"
              fullWidth
              margin="normal"
              value={formState.rental_name}
              onChange={handleChange}
              required
            />
            
            {/* Address Autocomplete Section */}
            <AddressAutocomplete onAddressSelect={handleAddressSelect} defaultValue={formState.address} />
            
            {/* Populated Timezone Field */}
            <TextField
              name="timezone"
              label="Timezone"
              fullWidth
              margin="normal"
              value={formState.timezone}
              disabled
            />

            <TextField
              name="default_check_in_time"
              label="Default Check-In Time"
              type="time"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={formState.default_check_in_time}
              onChange={handleChange}
            />

            <TextField
              name="default_check_out_time"
              label="Default Check-Out Time"
              type="time"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={formState.default_check_out_time}
              onChange={handleChange}
            />
            
            <Divider sx={{ my: 2 }} />

            {/* Calendar Links Section */}
            <Typography variant="h6">Calendar Links</Typography>
            <FormControlLabel
              control={
                <Checkbox
                  name="calendar_integration_enabled"
                  checked={formState.calendar_config.calendar_integration_enabled}
                  onChange={handleCalendarChange}
                />
              }
              label="Enable Calendar Integration"
            />

            <TextField
              name="calendar_vrbo_ical_link"
              label="VRBO iCal Link"
              fullWidth
              margin="normal"
              value={formState.calendar_config.calendar_vrbo_ical_link}
              onChange={handleCalendarChange}
            />
            {statusCheckState.vrboStatus && <Typography variant="body2" color="error" sx={{ mt: 1 }}>{statusCheckState.vrboStatus}</Typography>}
            <Button  onClick={() => handleICalLinkTest(formState.calendar_config.calendar_vrbo_ical_link, 'vrbo')} variant="contained" color="primary" sx={{ mt: 1 }}>
              Test Link
            </Button>

            <TextField
              name="calendar_airbnb_ical_link"
              label="Airbnb iCal Link"
              fullWidth
              margin="normal"
              value={formState.calendar_config.calendar_airbnb_ical_link}
              onChange={handleCalendarChange}
            />
            {statusCheckState.airbnbStatus && <Typography variant="body2" color="error" sx={{ mt: 1 }}>{statusCheckState.airbnbStatus}</Typography>}
            <Button  onClick={() => handleICalLinkTest(formState.calendar_config.calendar_airbnb_ical_link, 'airbnb')} variant="contained" color="primary" sx={{ mt: 1 }}>
              Test Link
            </Button>

            <TextField
              name="calendar_custom_ical_link"
              label="Custom iCal Link"
              fullWidth
              margin="normal"
              value={formState.calendar_config.calendar_custom_ical_link}
              onChange={handleCalendarChange}
            />
            {statusCheckState.customIcalStatus && <Typography variant="body2" color="error" sx={{ mt: 1 }}>{statusCheckState.customIcalStatus}</Typography>}
            <Button  onClick={() => handleICalLinkTest(formState.calendar_config.calendar_airbnb_ical_link, 'customIcal')} variant="contained" color="primary" sx={{ mt: 1 }}>
              Test Link
            </Button>

            <Divider sx={{ my: 2 }} />

            {/* Home Assistant Configuration Section */}
            <Typography variant="h6">Home Assistant Configuration</Typography>
            <FormControlLabel
              control={
                <Checkbox
                  name="home_assistant_integration_enabled"
                  checked={formState.home_assistant_config.home_assistant_integration_enabled}
                  onChange={handleHomeAssistantChange}
                />
              }
              label="Enable Home Assistant Integration"
            />
            <TextField
              name="home_assistant_host"
              label="Home Assistant Host"
              fullWidth
              margin="normal"
              value={formState.home_assistant_config.home_assistant_host}
              onChange={handleHomeAssistantChange}
            />
            <TextField
              name="home_assistant_api_key"
              label="Home Assistant API Key"
              fullWidth
              margin="normal"
              value={formState.home_assistant_config.home_assistant_api_key}
              onChange={handleHomeAssistantChange}
              type="password"
            />
            {statusCheckState.HomeAssistantStatus && <Typography variant="body2" color="error" sx={{ mt: 1 }}>{statusCheckState.HomeAssistantStatus}</Typography>}
            <Button onClick={handleHomeAssistantTest} variant="contained" color="primary" sx={{ mt: 2 }}>
              Test Connection
            </Button>
            <TextField
              name="home_assistant_guest_label"
              label="Guest Label"
              fullWidth
              margin="normal"
              value={formState.home_assistant_config.home_assistant_guest_label}
              onChange={handleHomeAssistantChange}
            />
          </Box>
        </LocalizationProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button form="rental-form" type="submit" color="primary">
          {editingRental ? 'Update' : 'Add'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RentalForm;
