import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import rentalService from '../services/rentalService';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Paper, Grid, Typography } from '@mui/material';
import Sidebar from './Sidebar';
import RentalForm from './RentalForm';

const RentalList = () => {
  const [rentals, setRentals] = useState([]);
  const [editingRental, setEditingRental] = useState(null);
  const [open, setOpen] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const { logout, isAuthenticated, getAccessTokenSilently } = useAuth0();

  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  useEffect(() => {
    fetchRentals();
  }, []);

  const fetchRentals = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await rentalService.getRentals(token);
      setRentals(response.data); // Ensure this state is updated properly
    } catch (error) {
      console.error(error);
    }
  };

  const handleCreate = async (rental) => {
    try {
      const token = await getAccessTokenSilently();
      await rentalService.createRental(token, rental);
      fetchRentals();
      setOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdate = async (rental) => {
    try {
      const token = await getAccessTokenSilently();
      rental.home_assistant_config.id = editingRental.home_assistant_config_id;
      await rentalService.updateRental(token, editingRental.id, rental);
      setEditingRental(null);
      fetchRentals();
      setOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const token = await getAccessTokenSilently();
      await rentalService.deleteRental(token, id);
      fetchRentals();
      setOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleOpen = (rental) => {
    setEditingRental(rental ? rental : null); // If rental is null, we're adding a new one
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditingRental(null); // Reset editingRental when the form is closed
  };

  const handleFormSubmit = (event) => {
    event.preventDefault(); // Make sure this is called on an actual event object
    const form = event.target;
  
    const rental = {
      rental_name: form.rental_name.value,
      address: form.address.value,
      timezone: form.timezone.value, // New field for Timezone
      default_check_in_time: form.default_check_in_time.value,
      default_check_out_time: form.default_check_out_time.value, // Use the checkOutTime state
      calendar_config: {
        integration_enabled: form.calendar_integration_enabled.checked,
        vrbo_ical_link: form.calendar_vrbo_ical_link.value,
        airbnb_ical_link: form.calendar_airbnb_ical_link.value,
        custom_ical_link: form.calendar_custom_ical_link.value,
      },
      home_assistant_config: {
        integration_enabled: form.home_assistant_integration_enabled.checked,
        host: form.home_assistant_host.value,
        api_key: form.home_assistant_api_key.value,
        guest_label: form.home_assistant_guest_label.value,
      },
    };
  
    if (editingRental) {
      handleUpdate(rental);
    } else {
      handleCreate(rental);
    }
  };

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      <Sidebar onLogout={logout} collapsed={collapsed} toggleCollapse={toggleCollapse} />
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column', padding: '16px', transition: 'margin-left 0.3s' }}>
        <h2>Rentals</h2>
        <div style={{ marginBottom: '16px' }}>
          <Button variant="contained" color="primary" onClick={() => handleOpen(null)} style={{ marginRight: '8px' }}>
            Add Rental
          </Button>
        </div>

        {/* Grid of Tiles */}
        <Grid container spacing={3}>
          {rentals.map((rental) => (
            <Grid item xs={12} sm={6} md={4} key={rental.id}>
              <Paper elevation={3} style={{ padding: '16px', textAlign: 'center', cursor: 'pointer' }} onClick={() => handleOpen(rental)}>
                <img
                  src="https://via.placeholder.com/150"
                  alt="Rental"
                  style={{ width: '100%', height: '150px', objectFit: 'cover', marginBottom: '16px' }}
                />
                <Typography variant="h6">{rental.rental_name}</Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>

        {/* Rental Form Dialog */}
        <RentalForm
          open={open}
          handleClose={handleClose}
          handleFormSubmit={handleFormSubmit}
          editingRental={editingRental}
          handleDelete={handleDelete}
        />
      </div>
    </div>
  );
};

export default RentalList;
