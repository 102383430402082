import React, { useState, useEffect } from 'react';
import bookingService from '../services/bookingService';
import rentalService from '../services/rentalService';
import { useAuth0 } from '@auth0/auth0-react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import {
  Button, IconButton, Paper
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Sidebar from './Sidebar';
import moment from 'moment';
import CopyToClipboardButton from './CopyToClipboard';
import BookingForm from './BookingForm';

const BookingList = () => {
  const [bookings, setBookings] = useState([]);
  const [rentals, setRentals] = useState([]);
  const [editingBooking, setEditingBooking] = useState(null);
  const [editingBookingRental, setEditingBookingRental] = useState(null);
  const [open, setOpen] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const { logout, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [isEditing, setIsEditing] = useState(false); // Track whether we're editing or creating

  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  useEffect(() => {
    fetchBookings();
    fetchRentals();
  }, []);

  const fetchBookings = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await bookingService.getBookings(token);
      setBookings(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchRentals = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await rentalService.getRentals(token);
      setRentals(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCreate = async (booking) => {
    try {
      const token = await getAccessTokenSilently();
      await bookingService.createBooking(token, booking);
      setIsEditing(false); // Reset mode
      fetchBookings();
      setOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdate = async (booking) => {
    try {
      const token = await getAccessTokenSilently();
      await bookingService.updateBooking(token, editingBooking.id, booking);
      setEditingBooking(null);
      setEditingBookingRental(null);
      setIsEditing(false); // Reset mode
      fetchBookings();
      setOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const token = await getAccessTokenSilently();
      await bookingService.deleteBooking(token, id);
      fetchBookings();
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteSelected = async () => {
    try {
      const token = await getAccessTokenSilently();
      await Promise.all(selectedRows.map(id => bookingService.deleteBooking(token, id)));
      fetchBookings();
      setSelectedRows([]); // Clear selection after deletion
    } catch (error) {
      console.error(error);
    }
  };

  const handleOpen = (booking, rental) => {
    setEditingBooking(booking ? booking : null); // If rental is null, we're adding a new one
    setEditingBookingRental(rental ? rental : null)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditingBooking(null); // Reset editingRental when the form is closed
    setEditingBookingRental(null)
  };

  const handleEdit = (booking, rentals) => {
    // Find the rental that corresponds to the booking's rental_id
    const rental = rentals.find(rental => rental.id === booking.rental_id);
  
    setIsEditing(true); // Set to edit mode
    setEditingBooking(booking); // Set the booking being edited
    setEditingBookingRental(rental); // Set the rental for the booking
    setOpen(true);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const booking = {
      rental_id: form.rental_id.value,
      check_in: new Date(`${form.check_in_date.value}T${form.check_in_time.value}:00`).toISOString(),
      check_out: new Date(`${form.check_out_date.value}T${form.check_out_time.value}:00`).toISOString(),
      guest_name: form.guest_name.value,
      guest_email: form.guest_email.value,
      phone_number: form.guest_phone_number.value,
      pincode: form.guest_pincode.value,
    };
  
    if (isEditing) {
      handleUpdate(booking);
    } else {
      handleCreate(booking);
    }
  };

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const columns = [
    { field: 'rental_name', headerName: 'Rental Name', width: 150 },
    { field: 'check_in', headerName: 'Check-in', width: 300, },
    { field: 'check_out', headerName: 'Check-out', width: 300, },
    { field: 'guest_name', headerName: 'Guest Name', width: 150 },
    { field: 'guest_email', headerName: 'Guest Email', width: 200 },
    { field: 'phone_number', headerName: 'Phone Number', width: 150 },
    { field: 'pincode', headerName: 'Pin Code', width: 100 },
    {
      field: 'guest_dashboard_link',
      headerName: 'Dashboard Link',
      width: 250,
      renderCell: (params) => (
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          {/* Fixed width link for alignment consistency */}
          <a
            href={params.value}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              flexGrow: 1,  // Allow link to expand to the container's width
              overflow: 'hidden',
              textOverflow: 'ellipsis', // Add ellipsis for long links
              whiteSpace: 'nowrap',
              marginRight: '8px',
              textDecoration: 'none',
              color: 'black'
            }}
          >
            {params.value}
          </a>
          {/* Consistent vertical alignment of copy button */}
          <CopyToClipboardButton url={params.value} />
        </div>
      )
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <>
          <IconButton onClick={() => handleEdit(params.row.booking, params.row.rentals)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDelete(params.row.booking.id)}>
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  const rows = bookings.map((booking) => ({
    id: booking.id,
    rental_id: booking.rental_id,
    rental_name: booking.rental.rental_name,
    check_in: moment(booking.check_in),
    check_out: moment(booking.check_out),
    guest_name: booking.guest_name,
    guest_email: booking.guest_email,
    phone_number: booking.phone_number,
    pincode: booking.pincode,
    guest_dashboard_link: booking.guest_dashboard_link,
    booking: booking,
    rentals: rentals,
  }));

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      <Sidebar onLogout={logout} collapsed={collapsed} toggleCollapse={toggleCollapse} />
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column', padding: '16px', transition: 'margin-left 0.3s' }}>
        <h2>Bookings</h2>
        <div style={{ marginBottom: '16px' }}>
          <Button variant="contained" color="primary"  onClick={() => handleOpen(null)} style={{ marginRight: '8px' }}>
            Add Booking
          </Button>
          <Button variant="contained" color="secondary" onClick={handleDeleteSelected} disabled={selectedRows.length === 0}>
            Delete Selected
          </Button>
        </div>
        <Paper style={{ flex: 1, overflow: 'auto' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 20]}
            checkboxSelection
            onSelectionModelChange={(newSelection) => setSelectedRows(newSelection)}
            selectionModel={selectedRows}
            components={{ Toolbar: GridToolbar }}
            style={{ minHeight: 0, width: '100%' }}
          />
        </Paper>
        {/* Rental Form Dialog */}
        <BookingForm
          open={open}
          handleClose={handleClose}
          handleFormSubmit={handleFormSubmit}
          editingBooking={editingBooking}
          editingBookingRental={editingBookingRental}
          handleDelete={handleDelete}
          rentals={rentals}
        />
      </div>
    </div>
  );
};

export default BookingList;
