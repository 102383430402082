import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Grid, Card, CardContent, Typography, Divider, Switch, Slider } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { fetchEntities, fetchGuestDetails, toggleEntityState } from '../services/guestService';
import { grey } from '@mui/material/colors';

const darkGrey = grey[700]; // Define the dark grey color

// Create a custom MUI theme
const customTheme = createTheme({
  palette: {
    primary: {
      main: darkGrey,
    },
    secondary: {
      main: grey[500],
    },
    background: {
      default: '#f5f5f5',
    },
    text: {
      primary: darkGrey,
      secondary: grey[600],
    },
  },
  components: {
    MuiSwitch: {
      styleOverrides: {
        thumb: {
          backgroundColor: darkGrey, // Override thumb color for switches
        },
        track: {
          backgroundColor: darkGrey, // Override track color for switches
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        rail: {
          color: darkGrey, // Slider rail color
        },
        thumb: {
          color: darkGrey, // Slider thumb color
        },
      },
    },
  },
});

const GuestDashboard = () => {
  const { id } = useParams(); // booking ID
  const [entities, setEntities] = useState([]);
  const [guestDetails, setGuestDetails] = useState(null);

  useEffect(() => {
    // Fetch guest details
    fetchGuestDetails(id)
      .then(data => {
        setGuestDetails(data);
      })
      .catch(error => {
        console.error('Error fetching guest details:', error);
      });

    // Fetch the entities
    fetchEntities(id)
      .then(data => {
        // Ensure data is an array
        if (Array.isArray(data)) {
          setEntities(data);
        } else {
          console.error('Fetched entities data is not an array:', data);
          setEntities([]); // Set an empty array if data is invalid
        }
      })
      .catch(error => {
        console.error('Error fetching entities:', error);
        setEntities([]); // Set an empty array in case of error
    });
  }, [id]);

  // Function to handle toggle of an entity (light, lock, cover)
  const handleToggle = (entity) => {
    let newState;

    if (entity.type === "light") {
      newState = entity.state === "on" ? "off" : "on";
    } else if (entity.type === "lock") {
      newState = entity.state === "locked" ? "unlocked" : "locked";
    } else if (entity.type === "cover") {
      newState = entity.state === "closed" ? "open" : "closed";
    }

    setEntities(prevEntities =>
      prevEntities.map(e =>
        e.id === entity.id ? { ...e, state: newState } : e
      )
    );

    toggleEntityState(id, entity.id, newState)
      .catch(error => {
        console.error('Error toggling entity state:', error);
        setEntities(prevEntities =>
          prevEntities.map(e =>
            e.id === entity.id ? { ...e, state: entity.state } : e
          )
        );
      });
  };

  const handleRangeTemperatureChange = (entity, newValue) => {
    const newTemperature = `${newValue[0]}-${newValue[1]}`; 

    setEntities(prevEntities =>
      prevEntities.map(e =>
        e.id === entity.id
          ? {
              ...e,
              attributes: {
                ...e.attributes,
                target_temp_low: newValue[0],
                target_temp_high: newValue[1],
              },
            }
          : e
      )
    );

    toggleEntityState(id, entity.id, newTemperature);
  };

  const handleTemperatureChange = (entity, newValue) => {
    const newTemperature = newValue.toString(); 

    setEntities(prevEntities =>
      prevEntities.map(e =>
        e.id === entity.id ? { ...e, attributes: { ...e.attributes, temperature: newTemperature } } : e
      )
    );

    toggleEntityState(id, entity.id, newTemperature);
  };

  if (!guestDetails) {
    return <Typography>Loading...</Typography>; 
  }

  const locksAndCovers = entities.filter(entity => entity.type === "lock" || entity.type === "cover");
  const climates = entities.filter(entity => entity.type === "climate");
  const lightsAndSwitches = entities.filter(entity => entity.type === "light" || entity.type === "switch");

  return (
    <ThemeProvider theme={customTheme}>
      <Container>
        <Card style={{ marginBottom: '20px' }}>
          <CardContent>
            <Typography variant="h4" gutterBottom>
              Welcome to {guestDetails.rental_name}
            </Typography>
            <Typography variant="h6" gutterBottom>
              Hello {guestDetails.guest_name}!
            </Typography>
            <Typography variant="body1" gutterBottom>
              Your door lock pin code is: <strong>{guestDetails.pin_code}</strong>
            </Typography>
            <Typography variant="body1">
              You can use this dashboard to control some elements of the house during your stay. Enjoy your trip!
            </Typography>
          </CardContent>
        </Card>

        {locksAndCovers.length > 0 && (
          <>
            <Typography variant="h5" gutterBottom>Locks & Covers</Typography>
            <Grid container spacing={3}>
              {locksAndCovers.map(entity => (
                <Grid item xs={12} sm={6} md={4} key={entity.id}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6" sx={{ mb: 2 }}>{entity.friendly_name || entity.name}</Typography>
                      <Typography color="textSecondary" sx={{ mb: 1 }}>
                        {entity.state === "unavailable"
                          ? `Entity is unavailable`
                          : `State: ${entity.state}`}
                      </Typography>
                      <Switch
                        checked={entity.type === "lock" ? entity.state === "locked" : entity.state === "closed"}
                        onChange={() => handleToggle(entity)}
                        disabled={entity.state === "unavailable"}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
            <Divider style={{ margin: '20px 0' }} />
          </>
        )}

        {climates.length > 0 && (
          <>
            <Typography variant="h5" gutterBottom>Climate</Typography>
            <Grid container spacing={3}>
              {climates.map(entity => (
                <Grid item xs={12} sm={6} md={4} key={entity.id}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6" sx={{ mb: 2 }}>{entity.friendly_name || entity.name}</Typography>
                      <Typography color="textSecondary" sx={{ mb: 1 }}>
                        {entity.state === "unavailable"
                          ? `Entity is unavailable`
                          : `State: ${entity.state}`}
                      </Typography>
                      {entity.type === "climate" && entity.attributes.current_temperature !== undefined && (
                        <Typography color="textSecondary" sx={{ mb: 5 }}>
                          Current Temperature: {entity.attributes.current_temperature}°F
                        </Typography>
                      )}
                      {entity.state === "heat_cool" ? (
                        <Slider
                          value={[
                            Number(entity.attributes.target_temp_low) || 60,
                            Number(entity.attributes.target_temp_high) || 73,
                          ]}
                          onChange={(e, newValue) => handleRangeTemperatureChange(entity, newValue)}
                          min={55} 
                          max={85}
                          step={1}
                          valueLabelDisplay="on" 
                          disabled={entity.state === "unavailable"}
                        />
                      ) : (
                        <Slider
                          value={Number(entity.attributes.temperature) || 60}
                          onChange={(e, newValue) => handleTemperatureChange(entity, newValue)}
                          min={55} 
                          max={85}
                          step={1}
                          valueLabelDisplay="on" 
                          disabled={entity.state === "unavailable"}
                        />
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
            <Divider style={{ margin: '20px 0' }} />
          </>
        )}

        {lightsAndSwitches.length > 0 && (
          <>
            <Typography variant="h5" gutterBottom>Lights & Switches</Typography>
            <Grid container spacing={3}>
              {lightsAndSwitches.map(entity => (
                <Grid item xs={12} sm={6} md={4} key={entity.id}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6" sx={{ mb: 2 }}>{entity.friendly_name || entity.name}</Typography>
                      <Typography color="textSecondary" sx={{ mb: 1 }}>
                        {entity.state === "unavailable"
                          ? `Entity is unavailable`
                          : `State: ${entity.state}`}
                      </Typography>
                      <Switch
                        checked={entity.state === "on"}
                        onChange={() => handleToggle(entity)}
                        disabled={entity.state === "unavailable"}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </Container>
    </ThemeProvider>
  );
};

export default GuestDashboard;
