import axios from 'axios';

const API_URL = process.env.REACT_APP_BACKEND_URL;

const getRentals = (token) => {
  return axios.get(`${API_URL}/rentals`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getRental = (token, id) => {
  return axios.get(`${API_URL}/rentals/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const createRental = (token, rental) => {
  return axios.post(`${API_URL}/rentals`, rental, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const updateRental = (token, id, rental) => {
  return axios.put(`${API_URL}/rentals/${id}`, rental, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const deleteRental = (token, id) => {
  return axios.delete(`${API_URL}/rentals/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const testHomeAssistantConnection = (host, apiKey) => {
  return axios.post(`${API_URL}/rentals/test-home-assistant`, {
    host: host,
    apiKey: apiKey,
  });
};

const testIcalLink = (icalLink) => {
  return axios.post(`${API_URL}/rentals/test-ical-link`, {
    icalLink: icalLink,
  });
};

export default {
  getRentals,
  getRental,
  createRental,
  updateRental,
  deleteRental,
  testHomeAssistantConnection,
  testIcalLink,
};
